import CountDown from './pages/CountDown'

const App = () => {
  return (
    <div>
      <CountDown/>
    </div>
  )
}

export default App